@import "../../../styles/_media_queries.scss";
@import "../../../styles/_colors.scss";

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #eeeeee;

  .link {
    @media screen and (min-width: $desktop) and (max-width: $max) {
      font-size: 14px;
    }
    @media screen and (min-width: $max) and (max-width: $_2k) {
      font-size: 16px;
    }
    @media screen and (min-width: $_2k) {
      font-size: 18px;
    }
  }

  @media screen and (min-width: $desktop) and (max-width: $max) {
    padding: 18px 18px 0 18px;
  }
  @media screen and (min-width: $max) and (max-width: $_2k) {
    padding: 35px 25px 0 25px;
  }
  @media screen and (min-width: $max) {
    padding: 35px 35px 0 35px;
  }

  @media screen and (min-width: $desktop) {
    .login-link {
      display: none;
    }
  }
}

.logo {
  width: 1.75em;
  display: grid;
  font-size: 3.25em;
  cursor: pointer;
  outline: none;

  @media screen and (min-width: $desktop) and (max-width: $max) {
    font-size: 4em;
  }
  @media screen and (min-width: $max) and (max-width: $_2k) {
    font-size: 4.5em;
  }
  @media screen and (min-width: $_2k) {
    font-size: 6em;
  }
}
