@import '../../../../../styles/_colors.scss';
@import '../../../../../styles/_fonts.scss';


.dropdownIndicator {
  margin-right: 8px;
}

.dropdownArrow {
  fill: #323232;
  opacity: 0.5;
}

.error_box {
  margin: 2px 8px;
  font-family: $error_text;
  font-weight: 300;
  font-size: 12px;
  color: $red_orange;
}
