@import '../../../../styles/_colors.scss';
@import '../../../../styles/_fonts';


.main {
  width: 100%;
  position: relative;
}

.amountLabel {
  position: absolute;
  left: 16px;
  top: 26px;
  z-index: 10;
  font-family: "Plain";
  font-size: 14px;
}

.amountLabel.amountLabelError {
  color: $red_orange;
}

%textLabel {
  position: absolute;
  color: inherit;
  font-family: $input_label;
  font-size: 14px;
  font-weight: 500;
  top: 16px;
}

.textLabel {
  @extend %textLabel;
}

.textLabel-appear {
  top: 6px;
  font-size: 12px;
  font-weight: 300;
}

.textLabel-enter {
  font-size: 14px;
  font-weight: 500;
  top: 16px;
}

.textLabel-enter-active {
  top: 6px;
  font-size: 12px;
  font-weight: 300;
  transition: font-size 0.2s, font-weight 0.2s, top 0.2s;
}

.textLabel-enter-done {
  top: 6px;
  font-size: 12px;
  font-weight: 300;
}

.textLabel-exit {
  top: 6px;
  font-size: 12px;
  font-weight: 300;
}

.textLabel-exit-active {
  top: 16px;
  font-size: 14px;
  font-weight: 500;
  transition: font-size 0.2s, font-weight 0.2s, top 0.2s;
}

.textLabel-exit-done {
  top: 16px;
  font-size: 14px;
  font-size: 500;
}

.input_box {
  width: calc(100% - 32px);
  height: 20px;
  position: absolute;
  bottom: 4px;
  border: none;
  outline: none;
  font-family: $input_label;
  font-weight: 500;
  font-size: 14px;
  color: inherit;
}

.input_box.amount_input_box {
  padding-left: 8px;
}

.textarea-container .input_box {
  height: 122px;
  padding: 0;
  resize: none;
}

.error_box {
  margin: 2px 8px;
  font-family: $error_text;
  font-weight: 300;
  font-size: 12px;
  color: $red_orange;
}

.error_element {
  margin: 4px 0;
}
