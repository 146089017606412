@import "../../styles/colors";
@import "../../styles/media_queries";

.Modal {
  background: $white;
  height: 100%;
  width: 100%;
  font-family: "Plain", Arial, Helvetica, sans-serif;
  max-width: 394px;
  max-height: 296px;
  padding: 36px 33px 27px 33px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
}

.Title {
  font-size: 25px;
  margin-bottom: 20px;
}

.Block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}

.CounterWrapper {
  width: 79px;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  float: left;
}

.Loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
}

.Counter {
  font-size: 40px;
}

.Subtitle {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  max-width: 222px;
}

@media screen and (max-width: $s-mobile) {
  .Title {
    font-size: 20px;
  }
  .Subtitle {
    font-size: 12px;
    line-height: 17px;
  }
  .Block {
    margin-bottom: 10px;
  }
  .Counter {
    font-size: 30px;
  }
  .CounterWrapper {
    margin-right: 10px;
  }
}
