@import "../../../styles/_colors.scss";
@import "../../../styles/_fonts.scss";
@import "../../../styles/_media_queries.scss";

.button_box {
  width: 100%;
  height: 48px;
  max-width: 462px;
  margin: 8px 0;
  background-color: $black;
  border: none;
  font-family: $button_label;
  font-weight: 500;
  font-size: 16px;
  color: $white;
  cursor: pointer;

  svg {
    margin-top: 5px;
  }
}

.disabled {
  background-color: $light_light_grey;
}
