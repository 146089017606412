.otp_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.otp_input {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 8px;
  width: 100%;
}

.otp_input_field {
  border: none;
  border-radius: 4px;
  font-size: 24px;
  outline: none;
}
