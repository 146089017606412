@import "./colors";

@font-face {
  font-family: "Plain";
  src: local("Plain-Light"), url(./fonts/Plain-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Plain";
  src: local("Plain-Medium"), url(./fonts/Plain-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Plain";
  src: local("Plain-Bold"), url(./fonts/Plain-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Tiempos";
  src: local("TiemposHeadlineApp-Medium"), url(./fonts/TiemposHeadlineApp-Medium.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

$input_label: "Plain", sans-serif;
$error_text: "Plain", sans-serif;
$button_label: "Plain", sans-serif;
$title: "Plain", sans-serif;

%body {
  font-family: "Plain", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: $dark_grey;
}
%link {
  font-family: "Plain", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: $orient;
}
%error {
  font-family: "Plain", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: $red_orange;
}
