@import '../../../../styles/_colors.scss';

.container {
  height: 48px;
  position: relative;
  margin: 8px 0;
  padding: 0px 16px;
  background-color: $white;
  border: solid 1px $white;
  border-radius: 4px;
  color: $dark_grey;
  outline: none;
};

.container.textarea-container {
  height: 150px;
}

.container_active {
  border: solid 1px $dark_grey;
}

.error {
  border: solid 1px $red_orange;
  color: $red_orange;
}
