$black: #000000;
$white: #ffffff;
$orient: #006982;
$blizzard_blue: #96e6f0;
$pink: #ffc5d2;
$password_wrapper: #6a6a6a;
$brand_teal: #006982;
$light_bg: #f5f5f5;
$fortune: #eb3327;
$forbes: #3e6296;
$techcrunch: #0aa868;
$money: #296695;
$button_highlight: #50eb82;
$hover_background: #dffee9;
$background: #eeeeee;

$dark_grey: #323232;
$red_orange: #eb5f2d;
$font_placeholder_grey: #9f9f9f;
$font_input_grey: #666;
$light_light_grey: #ababab;
$light_grey: #999999;
$text_primary: #1e1e1e;
$gray_200: #D7DDE2;
$gray_800: #1A2428;
