@import "../../styles/media_queries";

.split-screen {
  display: flex;
  justify-content: center;

  & > div {
    flex-shrink: 0;

    & > * {
      flex-shrink: 0;
    }
  }

  &-line {
    background: #000000;
    opacity: 0.2;
    height: 330px;
    width: 1px;
    margin: 0 70px;
  }

  &-page {
    max-width: 350px;
    width: 100%;
  }
}

.split-page {
  .page {
    min-height: initial;
  }
  align-items: center;
}
