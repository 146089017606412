.path_large {
  animation: large_animation 2s linear infinite;
  transform-origin: center;
  stroke-width: 4px;
}

@keyframes large_animation {
  0% {
    stroke-dasharray: 0 114;
    transform: rotateZ(0deg);
  }
  50% {
    stroke-dasharray: 60 114;
    transform: rotateZ(225deg);
  }
  100% {
    stroke-dasharray: 0 114;
    transform: rotateZ(750deg);
  }
}

.path_medium {
  animation: medium_animation 2.5s linear infinite;
  transform-origin: center;
  stroke-width: 3px;
  margin-top: 5px;
}

@keyframes medium_animation {
  0% {
    stroke-dasharray: 0 114;
    transform: rotateZ(0deg);
  }
  50% {
    stroke-dasharray: 34 114;
    transform: rotateZ(350deg);
  }
  100% {
    stroke-dasharray: 0 114;
    transform: rotateZ(1070deg);
  }
}

.path_small {
  animation: small_animation 3s linear infinite;
  transform-origin: center;
  stroke-width: 2px;
}

@keyframes small_animation {
  0% {
    stroke-dasharray: 0 114;
    transform: rotateZ(0deg);
  }
  50% {
    stroke-dasharray: 16 114;
    transform: rotateZ(225deg);
  }
  100% {
    stroke-dasharray: 0 114;
    transform: rotateZ(700deg);
  }
}
